












import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { saveAs } from "file-saver";

export default defineComponent({
  props: {
    singleInvoice: {
      type: Object,
      required: false,
      default: "",
    },
  },

  setup(props, { root, emit }) {
    const state = reactive({
      loading: false,
      empty: false,
      loaded: false,
      error: false,
      invoice: "",
    });

    const getInvoice = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/invoice/${props.singleInvoice.s_fak_id}`, {
          params: { type: 0 },
        })
        .then(({ data }) => {
          state.empty = false;
          state.invoice = data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
          } else {
            console.log(error);
            state.invoice = "";
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    if (props.singleInvoice.s_fak_id) {
      onMounted(getInvoice);
    }

    watch(() => props.singleInvoice, getInvoice, { deep: true });

    const getPdf = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/invoice/${props.singleInvoice.s_fak_id}`, {
          params: { type: 2 },
          responseType: "blob",
        })
        .then(({ data }) => {
          state.empty = false;
          saveAs(
            data,
            `${root.$tc("layout.misc.invoiceNo")} ${
              props.singleInvoice.s_fak_numer
            }.pdf`
          );
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.error = true;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const close = () => {
      emit("close-invoice");
    };

    return { state, getPdf, close };
  },
});
